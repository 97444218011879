@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply font-open-sans;
}

body {
    * {
        @apply font-open-sans;
    }
}

.scrollable-div::-webkit-scrollbar {
    display: none;
}

.scrollable-div {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.cldVideoPlayer-wrapper {
    > div {
        @apply h-dvh max-h-dvh min-h-[560px] overflow-hidden;
    }

    .cld-video-player {
        &.vjs-fluid:not(.vjs-audio-only-mode) {
            @apply h-dvh max-h-dvh min-h-[560px];
        }

        video {
            @apply object-cover h-dvh max-h-dvh min-h-[560px];
        }
    }
}

.policyPage-wrapper {
    > div {
        @apply pb-[60px] max-w-[900px] w-full;
    }

    > a {
        @apply pb-[60px] w-full;
    }

    .space-y-4 {
        @apply pb-10 sm:pb-[60px];
    }

    .space-y-9 {
        @apply max-w-[1130px] w-full;
    }
}

.carousel-container {
    overflow: hidden;
    position: relative;
    height: 160px;

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 200px;
        z-index: 10;
        left: 0;
        top: 0;
        bottom: 0;
    }

    &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 200px;
        z-index: 10;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &.carousel-container-light {
        &:before {

            @apply bg-gradient-carousel-light-left;
        }

        &:after {

            @apply bg-gradient-carousel-light-right;
        }
    }

    &.carousel-container-dark {
        &:before {

            @apply bg-gradient-carousel-dark-left;
        }

        &:after {

            @apply bg-gradient-carousel-dark-right;
        }
    }
}

.carousel-track {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    transform: translateX(0);
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    gap: 60px;
    height: 160px;
    animation: slide 30s linear infinite;
    padding: 0 30px;
}

.vjs-loading-spinner {
    @apply !hidden;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@-webkit-keyframes slide {
    0% {
        -webkit-transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-50%);
    }
}

@-moz-keyframes slide {
    0% {
        -moz-transform: translateX(0);
    }
    100% {
        -moz-transform: translateX(-50%);
    }
}

@-o-keyframes slide {
    0% {
        -o-transform: translateX(0);
    }
    100% {
        -o-transform: translateX(-50%);
    }
}


@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

video[id^='collapo-video-player'] {
    @apply !object-cover;
}

div:has(> video[id^='collapo-video-player']) {
    @apply !h-full !pt-0;
}

div:has(> div[id^='collapo-video-player']) {
    @apply h-full;
}

input:autofill {
  background-color: transparent;
}

.rotatex {
  transform: rotateX(180deg);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input[data-com-onepassword-filled="dark"] {
  transition: background-color 5000s ease-in-out 0s;
  border-bottom: 1px solid #FFF;
  -webkit-text-fill-color: #FFF;
  color: #FFF;
  -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
  box-shadow: 0 0 0px 1000px #00000000 inset;
  outline: none;
}

.text-shadow {
  text-shadow: 0px 0px 4px #00B9F2;
}

.cld-video-player {
    .vjs-control-bar .vjs-control.vjs-progress-control-events-blocker:before,
    .vjs-progress-control.vjs-control:before {
        @apply hidden;
    }
}

html {
  scroll-behavior: smooth;
}